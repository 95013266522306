import { Wrench } from '@phosphor-icons/react';
import { useTranslations } from 'use-intl';

interface IProps {
  size: 'xl' | 'lg';
}
const Maintenance = ({ size }: IProps) => {
  const t = useTranslations('Global');

  return (
    <main id="main" className="flex min-h-screen items-center justify-center">
      <div className="relative flex items-center justify-center p-8">
        <div className="text-center">
          <div className="flex justify-center">
            <Wrench
              size={32}
              className="mb-5 text-primary dark:text-primary-40"
              weight="fill"
            />
          </div>
          <h2 className={`${size === 'xl' ? 'text-xl' : 'text-lg'} mb-5`}>
            {t('maintenance')}
          </h2>
          <div className={`${size === 'xl' ? 'text-md' : 'text-base'} mb-8`}>
            <p className="mb-5 last:mb-0">{t('maintenanceMessage')}</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Maintenance;
