import { useCallback, useEffect, useMemo } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { isMobileOnly } from 'react-device-detect';
import { MapProvider } from 'react-map-gl';
import {
  Link,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  type Location,
} from 'react-router-dom';
import { useTranslations } from 'use-intl';
import { useDarkMode } from 'usehooks-ts';

import { Button, Loader } from '@plot/ui';

import { useProfile } from '@/lib/api';
import { TransitionStateProvider, useAuth, useWorkspace } from '@/lib/contexts';
import { useSection } from '@/lib/hooks';

import Header from '@/components/header';
import { WaitingList } from '@/components/waiting-list';
import { Workspace } from '@/components/workspace';

function PageTransition({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const section = useSection();

  const context = useMemo(() => {
    switch (section) {
      case '':
      case undefined:
      case 'workspaces':
        return 'root';

      case 'profile':
      case 'billing':
        return 'account';

      case 'settings':
      case 'members':
      case 'subscription':
      case 'invoices':
        return 'manage';

      default:
        return section;
    }
  }, [section]);

  const transition = useTransition(context, {
    initial: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    from: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(-100%, 0, 0)'
        : 'translate3d(100%, 0, 0)',
    },
    enter: {
      // opacity: 1,
      transform: 'translate3d(0%, 0, 0)',
    },
    leave: {
      // opacity: 0,
      transform: (location.state as { back: boolean } | null)?.back
        ? 'translate3d(100%, 0, 0)'
        : 'translate3d(-100%, 0, 0)',
    },
  });

  return transition(
    (style, item, transitionState) =>
      item &&
      item !== 'root' && (
        <animated.div
          key={item}
          style={style}
          className="absolute bottom-0 left-0 top-0 z-10 h-full w-full overflow-y-auto bg-neutral-0 dark:bg-neutral-70"
        >
          <TransitionStateProvider transitionState={transitionState}>
            {children}
          </TransitionStateProvider>
        </animated.div>
      )
  );
}

export function WorkspaceLayout() {
  const { isDarkMode } = useDarkMode();
  const t = useTranslations('Global');
  const location = useLocation();
  const auth = useAuth();
  const profile = useProfile();

  const { loading, workspace, workspaces } = useWorkspace();

  const getKey = useCallback((location: Location) => location.key, []);

  useEffect(() => {
    if (!document) return;

    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    return () => {
      document.documentElement.classList.remove('dark');
    };
  }, [isDarkMode]);

  if (!auth.token) {
    return <Navigate to={`/signin`} state={{ from: location }} replace />;
  }

  if (isMobileOnly) {
    return <Navigate to={`/not-supported`} />;
  }

  return (
    <MapProvider>
      <Header />
      {!workspace && !loading && workspaces?.length !== 0 ? (
        <div className="relative flex h-screen items-center justify-center p-8">
          <div className="text-center">
            <h2 className="mb-6 text-4xl">
              <div className="text-primary">404</div>
              {t('workspaceNotFound')}
            </h2>
            <div className="mb-8 text-md">
              {t.rich('notFoundMessage', {
                paragraph: (chunks) => (
                  <p className="mb-5 last:mb-0">{chunks}</p>
                ),
              })}
            </div>
            <Button as={Link} to={'/'} variant="neutral">
              {t('home')}
            </Button>
          </div>
        </div>
      ) : (
        <main id="main" className="relative min-h-[calc(100vh-56px)]">
          {profile.isFetched &&
            (profile.data?.isOnWaitingList ? (
              <WaitingList />
            ) : (
              <div>
                <Workspace />
                <PageTransition>
                  <Outlet />
                </PageTransition>
              </div>
            ))}
        </main>
      )}
      {profile.isFetching && <Loader fullscreen backdrop />}
      <ScrollRestoration getKey={getKey} />
    </MapProvider>
  );
}
export default WorkspaceLayout;
