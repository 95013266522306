import { MapMode } from '@/lib/types';

export const INITIAL_VIEW_STATE = {
  longitude: 10.762,
  latitude: 59.915,
  zoom: 13,
};

export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/streets-v12';

export enum MapModeLayer {
  PLOTS = 'plots',
  PLOTS_FILL = 'plots__fill',
  PROJECTS_SHAPE = 'projects__shape',
  PROJECTS_SHAPE_HIGHLIGHTED = 'projects__shape_hovered',
  PROJECTS_SHAPE_HOVERED = 'projects__shape_hovered2',
  PROJECTS_STATS_LABEL = 'projects__stats_label',
  PROJECTS_STATS_LABEL_HIGHLIGHTED = 'projects__stats_label_hovered',
  PROJECTS_STATS_LABEL_HOVERED = 'projects__stats_label_hovered2',
  PROJECTS_TITLE_LABEL = 'projects__title_label',
  UNITS_SHAPE = 'units__shape',
  UNITS_SHAPE_HIGHLIGHTED = 'units__shape_hovered',
  UNITS_SHAPE_HOVERED = 'units__shape_hovered2',
  UNITS_LABEL = 'units__label',
  UNITS_LABEL_HIGHTLIGHTED = 'units__label_hovered',
  UNITS_LABEL_HOVERED = 'units__label_hovered2',
  HEATMAP = 'heatmap',
  REGULATORY_PLANS = 'regulatory_plans',
  MUNICIPAL_PLANS = 'municipal_plans',
  RAILWAYS_NOISE_LEVELS = 'railways_noise_levels',
  AIRPORTS_NOISE_LEVELS = 'airports_noise_levels',
  ROADS_NOISE_LEVELS = 'roads_noise_levels',
}

export enum MapModeSourceLayer {
  PLOTS = 'plots',
  PROJECTS = 'new-projects',
  UNITS = 'used-units',
  UNITS_HEATMAP = 'hmap-units',
}

export enum MapModeSource {
  UNITS = 'units-source',
  UNITS_HIGHLIGHTED = 'units-source-hovered',
  UNITS_HOVERED = 'units-source-hovered2',
  PROJECTS = 'projects-source',
  PROJECTS_HIGHLIGHTED = 'projects-source-hovered',
  PROJECTS_HOVERED = 'projects-source-hovered2',
  PLOTS = 'plots-source',
  UNITS_HEATMAP = 'units-heatmap-source',
  REGULATORY_PLANS = 'regulatory_plans',
  MUNICIPAL_PLANS = 'municipal_plans',
  RAILWAYS_NOISE_LEVELS = 'railways_noise_levels',
  AIRPORTS_NOISE_LEVELS = 'airports_noise_levels',
  ROADS_NOISE_LEVELS = 'roads_noise_levels',
}

export const hmapMapModeFeatures: { [key in MapMode]?: string } = {
  [MapMode.PRIMARY_AREA_HMAP]: 'primary-area',
  [MapMode.USABLE_AREA_HMAP]: 'usable-area',
  [MapMode.ROOMS_HMAP]: 'rooms',
  [MapMode.BEDROOMS_HMAP]: 'bedrooms',
  [MapMode.LISTING_PRICE_HMAP]: 'listing-price',
  [MapMode.TRANSACTION_COSTS_HMAP]: 'transaction-costs',
  [MapMode.JOIN_DEBT_HMAP]: 'joint-debt',
  [MapMode.PRICE_HMAP]: 'selling-price',
  [MapMode.DAYS_ON_MARKET_HMAP]: 'days-on-market',
  [MapMode.SQM_PRICE_HMAP]: 'sqm-price',
};

export const hmapMapModeUnits: { [key in MapMode]?: string } = {
  [MapMode.PRIMARY_AREA_HMAP]: 'm2',
  [MapMode.USABLE_AREA_HMAP]: 'm2',
  [MapMode.ROOMS_HMAP]: 'rooms',
  [MapMode.BEDROOMS_HMAP]: 'bedrooms',
  [MapMode.LISTING_PRICE_HMAP]: 'kr',
  [MapMode.TRANSACTION_COSTS_HMAP]: 'kr',
  [MapMode.JOIN_DEBT_HMAP]: 'm2',
  [MapMode.PRICE_HMAP]: 'kr',
  [MapMode.DAYS_ON_MARKET_HMAP]: 'days',
  [MapMode.SQM_PRICE_HMAP]: 'kr/m2',
};

export const mapIcons = [
  { url: '/map-icons/ApartmentIcon.png', name: 'ApartmentIcon' },
  { url: '/map-icons/ApartmentIconPrimary.png', name: 'ApartmentIconPrimary' },
  {
    url: '/map-icons/SemiDetachedHouseIcon.png',
    name: 'SemiDetachedHouseIcon',
  },
  {
    url: '/map-icons/SemiDetachedHouseIconPrimary.png',
    name: 'SemiDetachedHouseIconPrimary',
  },
  { url: '/map-icons/TownhouseIcon.png', name: 'TownhouseIcon' },
  { url: '/map-icons/TownhouseIconPrimary.png', name: 'TownhouseIconPrimary' },
  { url: '/map-icons/DetachedHouseIcon.png', name: 'DetachedHouseIcon' },
  {
    url: '/map-icons/DetachedHouseIconPrimary.png',
    name: 'DetachedHouseIconPrimary',
  },
  { url: '/map-icons/ProjectLabel.png', name: 'ProjectLabel' },
  { url: '/map-icons/CabinIcon.png', name: 'CabinIcon' },
  { url: '/map-icons/CabinIconPrimary.png', name: 'CabinIconPrimary' },
];
