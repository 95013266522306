import { format, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { current, produce } from 'immer';
import { create } from 'zustand';

import {
  FloorRemoveType,
  OwnershipType,
  PropertyType,
  SaleStatus,
  UseType,
} from '@plot/plot-api';

import { Dates } from '@/components/area-report/filter-control/component/date-range';
import { RangeType } from '@/components/area-report/filter-control/component/range';

import {
  IMapFilters,
  IMapState,
  MapControlType,
  MapFilterType,
  MapMode,
} from '../types';

export const initialMapFilters: IMapFilters = {
  newProjectActive: {
    id: 'newProjectActive',
    label: ``,
    apiParam: 'include_datasets',
    type: MapFilterType.TOGGLE,
    values: true,
  },
  newProjectActiveBetween: {
    id: 'newProjectActiveBetween',
    label: `label.projectsOnTheMarket`,
    apiParam: ['new_project__active_before', 'new_project__active_after'],
    type: MapFilterType.DATE_SELECTOR,
    dateRange: '2years',
    values: {
      minDate: startOfMonth(subMonths(startOfMonth(new Date()), 3)),
      maxDate: startOfDay(new Date()),
    },
    minDate: new Date('2020-03-01'),
    tooltip: 'tooltip.newProjectsActiveBetween',
  },
  newProjectSaleStatus: {
    id: 'newProjectSaleStatus',
    apiParam: 'new_project__unit__sale_status',
    label: `label.saleStatus`,
    type: MapFilterType.CHECKBOX,
    //TODO SOLD SaleStatus to SOLDOUT
    values: [SaleStatus.FORSALE, SaleStatus.SOLD, SaleStatus.WITHDRAWN].map(
      (value) => ({
        id: value,
        label: `filterSaleStatus.${value}`,
        checked: false,
      })
    ),
    tooltip: 'tooltip.newProjectSaleStatus',
  },
  newProjectUnitSoldAt: {
    id: 'newProjectUnitSoldAt',
    label: `label.unitsSoldAt`,
    apiParam: [
      'new_project__unit__sold_at_before',
      'new_project__unit__sold_at_after',
    ],
    type: MapFilterType.DATE_SELECTOR,
    dateRange: '1month',
    values: {
      minDate: null,
      maxDate: null,
    },
    tooltip: 'tooltip.unitsSoldAt',
  },
  newProjectUnitListedAt: {
    id: 'newProjectUnitListedAt',
    label: `label.unitsListedAt`,
    apiParam: [
      'new_project__unit__listed_at_before',
      'new_project__unit__listed_at_after',
    ],
    type: MapFilterType.DATE_SELECTOR,
    dateRange: '1month',
    values: {
      minDate: null,
      maxDate: null,
    },
    tooltip: 'tooltip.unitsListedAt',
  },
  newProjectUnitPropertyType: {
    id: 'newProjectUnitPropertyType',
    apiParam: 'new_project__unit__property_type',
    label: `label.propertyType`,
    type: MapFilterType.CHECKBOX,
    values: [
      PropertyType.APARTMENT,
      PropertyType.SEMIDETACHED,
      PropertyType.TOWNHOUSE,
      PropertyType.DETACHED,
      PropertyType.CABIN,
    ].map((value) => ({
      id: value,
      label: `filterPropertyType.${value}`,
      checked: false,
    })),
  },
  newProjectUnitOwnershipType: {
    id: 'newProjectUnitOwnershipType',
    apiParam: 'new_project__unit__ownership_type',
    label: 'label.ownershipType',
    type: MapFilterType.CHECKBOX,
    values: [OwnershipType.OWNED, OwnershipType.COOPERATIVE].map((value) => ({
      id: value,
      label: `filterOwnershipType.${value}`,
      checked: false,
    })),
  },
  newProjectUnitSellingPrice: {
    id: 'newProjectUnitSellingPrice',
    label: `label.price`,
    apiParam: [
      'new_project__unit__selling_price_min',
      'new_project__unit__selling_price_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.price`,
    values: [null, null],
  },
  newProjectUnitSqmPrice: {
    id: 'newProjectUnitSqmPrice',
    label: `label.sqmPrice`,
    apiParam: [
      'new_project__unit__sqm_price_min',
      'new_project__unit__sqm_price_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.sqmPrice`,
    values: [null, null],
  },
  newProjectUsableSize: {
    id: 'newProjectUsableSize',
    label: `label.usableArea`,
    apiParam: [
      'new_project__unit__usable_area_min',
      'new_project__unit__usable_area_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.usableArea`,
    values: [null, null],
  },
  newProjectUnitDaysOnMarketMin: {
    id: 'newProjectUnitDaysOnMarketMin',
    label: `label.daysOnMarket`,
    apiParam: [
      'new_project__unit__days_on_market_min',
      'new_project__unit__days_on_market_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.daysOnMarket`,
    values: [null, null],
  },
  newProjectUnitRooms: {
    id: 'newProjectUnitRooms',
    label: `label.rooms`,
    apiParam: ['new_project__unit__rooms_min', 'new_project__unit__rooms_max'],
    type: MapFilterType.RANGE,
    unit: `unit.rooms`,
    values: [null, null],
  },
  newProjectFloors: {
    id: 'newProjectFloors',
    apiParam: [
      'new_project__unit__is_bottom_floor',
      'new_project__unit__is_top_floor',
    ],
    label: `label.floors`,
    tooltip: 'tooltip.floors',
    type: MapFilterType.CHECKBOX_FLAG,
    values: [FloorRemoveType.GROUND_FLOOR, FloorRemoveType.TOP_FLOOR].map(
      (value) => ({
        id: value,
        label: `filterFloorRemoveType.${value}`,
        checked: false,
      })
    ),
  },
  usedUnitActive: {
    id: 'usedUnitActive',
    label: ``,
    apiParam: 'include_datasets',
    type: MapFilterType.TOGGLE,
    values: true,
  },
  usedUnitActiveBetween: {
    id: 'usedUnitActiveBetween',
    label: `label.unitsOnTheMarket`,
    apiParam: ['used_unit__active_before', 'used_unit__active_after'],
    dateRange: '1year',
    type: MapFilterType.DATE_SELECTOR,
    values: {
      minDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      maxDate: startOfDay(new Date()),
    },
    minDate: new Date('2023-03-04'),
    // To be updated
    // minDate: new Date('2020-03-01'),
    tooltip: 'tooltip.unitsActiveBetween',
  },
  usedUnitSaleStatus: {
    id: 'usedUnitSaleStatus',
    apiParam: 'used_unit__sale_status',
    label: `label.saleStatus`,
    type: MapFilterType.CHECKBOX,
    values: [SaleStatus.FORSALE, SaleStatus.SOLD, SaleStatus.WITHDRAWN].map(
      (value) => ({
        id: value,
        label: `filterSaleStatus.${value}`,
        checked: false,
      })
    ),
  },
  usedUnitSoldAt: {
    id: 'usedUnitSoldAt',
    label: `label.unitsSoldAt`,
    apiParam: ['used_unit__sold_at_before', 'used_unit__sold_at_after'],
    type: MapFilterType.DATE_SELECTOR,
    dateRange: '1month',
    values: {
      minDate: null,
      maxDate: null,
    },
    tooltip: 'tooltip.unitsSoldAt',
    disabledTooltip: 'disabledTooltip.usedUnitsSoldAtDisabled',
  },
  usedUnitListedAt: {
    id: 'usedUnitListedAt',
    label: `label.unitsListedAt`,
    apiParam: ['used_unit__listed_at_before', 'used_unit__listed_at_after'],
    type: MapFilterType.DATE_SELECTOR,
    dateRange: '1month',
    values: {
      minDate: null,
      maxDate: null,
    },
    tooltip: 'tooltip.unitsListedAt',
  },
  usedUnitPropertyType: {
    id: 'usedUnitPropertyType',
    apiParam: 'used_unit__property_type',
    label: `label.propertyType`,
    type: MapFilterType.CHECKBOX,
    values: [
      PropertyType.APARTMENT,
      PropertyType.SEMIDETACHED,
      PropertyType.TOWNHOUSE,
      PropertyType.DETACHED,
      PropertyType.CABIN,
    ].map((value) => ({
      id: value,
      label: `filterPropertyType.${value}`,
      checked: false,
    })),
  },
  usedUnitOwnershipType: {
    id: 'usedUnitOwnershipType',
    apiParam: 'used_unit__ownership_type',
    label: 'label.ownershipType',
    type: MapFilterType.CHECKBOX,
    values: [
      OwnershipType.OWNED,
      OwnershipType.COOPERATIVE,
      OwnershipType.STOCK,
      OwnershipType.BOND,
    ].map((value) => ({
      id: value,
      label: `filterOwnershipType.${value}`,
      checked: false,
    })),
  },
  usedUnitUseType: {
    id: 'usedUnitUseType',
    apiParam: 'used_unit__use_type',
    label: 'label.useType',
    type: MapFilterType.CHECKBOX,
    values: [UseType.RESIDENTIAL, UseType.RECREATIONAL].map((value) => ({
      id: value,
      label: `filterUseType.${value}`,
      checked: false,
    })),
  },
  usedUnitSellingPrice: {
    id: 'usedUnitSellingPrice',
    label: `label.price`,
    apiParam: ['used_unit__selling_price_min', 'used_unit__selling_price_max'],
    type: MapFilterType.RANGE,
    unit: `unit.price`,
    values: [null, null],
  },
  usedUnitSqmPrice: {
    id: 'usedUnitSqmPrice',
    label: `label.sqmPrice`,
    apiParam: ['used_unit__sqm_price_min', 'used_unit__sqm_price_max'],
    type: MapFilterType.RANGE,
    unit: `unit.sqmPrice`,
    values: [null, null],
  },
  usedUnitUsableArea: {
    id: 'usedUnitUsableArea',
    label: `label.usableArea`,
    apiParam: ['used_unit__usable_area_min', 'used_unit__usable_area_max'],
    type: MapFilterType.RANGE,
    unit: `unit.usableArea`,
    values: [null, null],
  },
  usedUnitDaysOnMarket: {
    id: 'usedUnitDaysOnMarket',
    label: `label.daysOnMarket`,
    apiParam: [
      'used_unit__days_on_market_min',
      'used_unit__days_on_market_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.daysOnMarket`,
    values: [null, null],
  },
  usedUnitRooms: {
    id: 'usedUnitRooms',
    label: `label.rooms`,
    apiParam: ['used_unit__rooms_min', 'used_unit__rooms_max'],
    type: MapFilterType.RANGE,
    unit: `unit.rooms`,
    values: [null, null],
  },
  usedUnitConstructionYear: {
    id: 'usedUnitConstructionYear',
    label: `label.constructionYear`,
    apiParam: [
      'used_unit__construction_year_min',
      'used_unit__construction_year_max',
    ],
    type: MapFilterType.RANGE,
    unit: `unit.years`,
    values: [null, null],
  },
};

export const useMapStore = create<IMapState>((set) => ({
  mapControl: 'none',
  setMapControl: (mapControl: MapControlType) => set({ mapControl }),
  mapMode: MapMode.BROWSING,
  setMapMode: (mapMode: MapMode) => set({ mapMode }),
  mapFilters: initialMapFilters,
  onFilterChange: undefined,
  setOnFilterChange: (fn) => set({ onFilterChange: fn }),
  updateToggleMapFilter: (filterId: string, value: boolean) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (targetFilter?.type === MapFilterType.TOGGLE) {
          targetFilter.values = value;
          if (draft.onFilterChange) {
            draft.onFilterChange(filterId, value, draft.mapFilters);
          }
        }
      })
    );
  },
  updateCheckboxMapFilter: (
    filterId: string,
    valueId: string,
    checked: boolean
  ) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (
          (targetFilter?.type === MapFilterType.CHECKBOX ||
            targetFilter?.type === MapFilterType.CHECKBOX_FLAG) &&
          targetFilter.values
        ) {
          const valueToUpdate = targetFilter.values.find(
            (value) => value.id === valueId
          );
          if (valueToUpdate) {
            valueToUpdate.checked = checked;
          }

          if (draft.onFilterChange) {
            draft.onFilterChange(
              filterId,
              current(targetFilter.values)
                .filter((value) => value.checked)
                .map((value) => value.id),
              current(draft.mapFilters)
            );
          }
        }
      })
    );
  },
  updateDateRangeMapFilter: (filterId: string, dates: Dates) => {
    const [start, end] = dates;
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (
          targetFilter?.type === MapFilterType.DATE_PICKER ||
          targetFilter?.type === MapFilterType.DATE_SELECTOR
        ) {
          targetFilter.values.minDate = start;
          targetFilter.values.maxDate = end;

          if (start && end) {
            if (draft.onFilterChange) {
              draft.onFilterChange(
                filterId,
                [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')],
                current(draft.mapFilters)
              );
            }
          }
        }
      })
    );
  },
  updateRangeMapFilter: (filterId: string, value: RangeType) => {
    set(
      produce((draft: IMapState) => {
        const targetFilter = draft.mapFilters[filterId];
        if (targetFilter?.type === MapFilterType.RANGE && targetFilter.values) {
          targetFilter.values = value;
          if (value[0] || value[1]) {
            if (draft.onFilterChange) {
              draft.onFilterChange(filterId, value, current(draft.mapFilters));
            }
          }
        }
      })
    );
  },
  resetFilter: (filterId: string) => {
    set(
      produce((draft: IMapState) => {
        draft.mapFilters[filterId] = initialMapFilters[filterId];
        if (draft.onFilterChange) {
          draft.onFilterChange(filterId, 'reset', current(draft.mapFilters));
        }
      })
    );
  },
  resetFilters: () => {
    set(
      produce((draft: IMapState) => {
        draft.mapFilters = initialMapFilters;
        if (draft.onFilterChange) {
          draft.onFilterChange('general', 'resetAll', initialMapFilters);
        }
      })
    );
  },
}));

export default useMapStore;
